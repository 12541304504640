import { useQuery } from '@tanstack/react-query';

import { ICategoriesApiResponseModelByView } from 'common/globalTypes/interfaces/apiResponseModel';
import categoriesApiHandler from 'infrastructure/apiHandlers/categories';
import useTranslations from 'hooks/common/useTranslations';
import { categoryKey } from './constants';

const useCategoriesQuery = (
  initialCategoriesFromServerSide: ICategoriesApiResponseModelByView[] = []
) => {
  const { t } = useTranslations('common');
  const categoriesQuery = useQuery({
    queryKey: [categoryKey],
    queryFn: () => categoriesApiHandler.getCategories({}),
    initialData: initialCategoriesFromServerSide,
    staleTime: Infinity
  });

  const getCurrentCategoryInfoByAlias = (categoryAlias: string) => {
    const { data: categories } = categoriesQuery;
    const category = categories.find(
      category => category.key === categoryAlias
    );

    return {
      title: category?.label || t('popularApps'),
      description: category?.description || t('mostPopularAppForSection')
    };
  };

  return {
    ...categoriesQuery,
    getCurrentCategoryInfoByAlias
  };
};

export default useCategoriesQuery;
